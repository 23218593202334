.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #ddd;
  margin-top: 50px;
  height: 250px;
}

.section {
  flex: 1;
  margin-right: 20px;
}

p {
  margin-top: 5px;
}

.descriptionfooter {
  font-size: 14px;
  color: #333;
  width: 250px;
}

.double{
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 16px;
  margin-bottom: 10px;
}

.menu-list {
  list-style-type: none;
  padding: 0;
}

li {
  margin-top: 5px;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  color: red;
}

.subscribe-container {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.subscribe-button {
  padding: 10px 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
}

@media (max-width: 768px) {
  .footer-container {
    width: 100%;  
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 20px;
    height: 400px;
  
  }

  .descriptionfooter{
    max-width: 100%;
    width: 200px;
  }

  .double{
    width: 100%; 
    display: flex;
  }
  
}


@media (max-width: 467px) {
  .footer-container{
    width: 100%;
    max-width: 467px;
    display: flex;
    flex-direction: column;
    height: auto;

    .descriptionfooter{
      max-width: 100%;
      width: 100%;
    }

  }

  .double{
    width: 100%;
    display: flex;
    padding: 30px 0px;
  }

}


.Toastify {
  &__toast {
    max-width: 400px; 
  }

  @media (max-width: 768px) {
    &__toast {
      max-width: 350px; 
    }
  }

  @media (max-width: 467px) {
    &__toast {
      max-width: 60%; 

    }
  }
}
