.table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }
  
  .styled-table td {
    border: 1px solid #ddd;
    padding: 10px;
    width: 250px;
    height: 60px;
  }
  
  .styled-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .styled-table tr:hover {
    background-color: #ddd;
  }
  
  .styled-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
  }

  .table-container{
    display: flex;
    margin-top: 50px;
    img{
        width: 600px;
        border-radius: 20px;
    }
    
    .single-div{
        margin-left: 200px;
    }

    p{
        margin-top: 20px;
    }
    .zoom-wrapper {
        overflow: hidden;
        cursor: zoom-in;
        border-radius: 20px;
      }
  }
  .similar-product-card{

    
    &:hover{
      a{
        color: red;
      }
    }
    img{
     max-width: 250px ;
     width: 100%;
      border-radius: 15px;
    }
    a{
      text-decoration: none;
      color: rgb(10, 133, 133);
    }
    h3{
      font-size: 15px;
      margin-top: 12px;
    }
  }
  
  .similar-container{
    h2{
      text-align: center;
      margin: 30px 0px;
    }
  }

@media(max-width:768px){
  .table-container{
    display: flex;
    flex-direction: column;
    width: 100%;


    .single-div{
      margin: 0;
      h1{
        margin: 30px 0px;
      }
    }
    .similar-product-card{
      img{
        max-width: 200px !important;
        width: 100% !important;
      }

      h3{
        font-size: 12px;
      }
    }
  }
  .similar-products-slider{
    width: 100%;
    img{
      width: 230px;
    }
  }
}

@media (max-width: 467px) {
  .table-container {
    flex-direction: column;
    width: 100%;
    padding: 10px;

    img {
      width: 100%;
      border-radius: 10px; 
    }

    .single-div {
      margin: 0;
      h1 {
        margin: 20px 0; 
        font-size: 1.5rem; 
      }
    }

    p {
      font-size: 0.9rem; 
    }
    
  }

  .styled-table td {
    
    font-size: 11px;
    padding: 8px; 
    height: 50px; 
  }

  .styled-table th {
    padding-top: 8px; 
    padding-bottom: 8px;
  }

  .similar-container {
    h2 {
      font-size: 1.5rem; 
    }
  }
  .similar-products-slider{
    width:100%;
    img{
      width: 150px;
    }
  }
}
