.collections-page {
  display: flex;

  .sidebar {
    width: 250px;
    padding: 20px;
    margin-top: 30px;

    .search-bar {
      margin-bottom: 20px;

      input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .categories {
      width: 100%;

      h3 {
        margin-bottom: 10px;
      }

      p {
        text-align: center;
        
        margin-bottom: 10px;
        padding: 8px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.2s;

        &:hover {
          background-color: #ddd;
        }

        &:active {
          background-color: #bbb;
        }
      }
    }
  }

  .collections {
    flex: 1;
    padding: 20px;
    margin-top: 30px;

    h2 {
      margin-bottom: 20px;
    }

    .card-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
    }

    .card {
      cursor: pointer;
      width: 100%;
      max-width: 200px;
      border-radius: 8px;
      overflow: hidden;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.05);

        h3 {
          color: red;
        }
      }

      .card-image {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }

      .card-content {
        padding: 10px;

        h3 {
          font-size: 15px;
          margin-bottom: 5px;
        }
      }
    }

    .no-results {
      font-size: 1.2em;
      color: #888;
      text-align: center;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .collections-page {
    flex-direction: row;

    .sidebar {
      width: 200px;
      margin-right: 10px;
    }
    .collections {
      padding: 0;
    }

    .collections .card-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }

}

@media (max-width: 467px) {
  .collections-page {
    flex-direction: column;

    .categories {
      height: auto;
      display: grid;
      grid-template-columns: auto auto;

      h3 {
        display: none;
      }

      p {
        text-align: center;
        width: 150px;
        height: 50px;
        background-color: #888;
        color: aliceblue;
      }
    }

    .sidebar {
      width: 100%;
      padding: 10px;
    }

    .collections .card-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .collections h2 {
      font-size: 1.2rem;
    }

    .card {
      max-width: 100%;
    }
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}