.contact-container {
  padding: 20px;
}

.contact-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  gap: 20px;
}

.contact-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-form h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form form input,
.contact-form form textarea {
  width: 463px;
  height: 58px;
  padding-left: 30px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #cccccc94;
}

.contact-form form textarea {
  padding-top: 20px;
  width: 463px;
  height: 236px;
  min-height: 100px;
}

.contact-form form button {
  width: 200px;
  height: 50px;
  padding: 10px;
  background-color: #ff3b3f;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
}

.contact-map {
  width: 50%;
}

.contact-map iframe {
  width: 100%;
  height: 600px;
  border: 0;
  border-radius: 10px;
}

.contact-info {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.contact-info .contact-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.contact-info .contact-item p {
  margin-left: 15px;
}

.contact-info .contact-item .icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.contact-form form button {
  width: 200px;
  height: 50px;
  padding: 10px;
  background-color: #ff3b3f;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-form form button:hover {
  background-color: #ff1e1f;
  transform: scale(1.05);
}

.contact-form form button:active {
  transform: scale(0.95);
  background-color: #cc0000;
}

.contact-form form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}


@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-info {
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }

  .contact-form form input,
  .contact-form form textarea {
    width: 100%;
  }

  .contact-map {
    width: 100%;
  }
}

/* Responsive styling for small screens */
@media (max-width: 467px) {
  .contact-container {
    padding: 10px;
  }

  .contact-content {
    padding: 10px;
    gap: 15px;
  }

  .contact-form h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  .contact-form form input,
  .contact-form form textarea {
    width: 100%;
    height: 48px;
    padding-left: 15px;
  }

  .contact-form form textarea {
    height: 180px;
    padding-top: 15px;
  }

  .contact-form form button {
    width: 100%;
    height: 45px;
    font-size: 0.9rem;
  }

  .contact-info {
    gap: 20px;

    svg{
      width: 36px;
      height: 36px;
    }
  }

  .contact-info .contact-item {
    font-size: 0.9rem;
  }

  .contact-map iframe {
    height: 300px;
  }
}



.Toastify {
  &__toast {
    max-width: 400px; 
  }

  @media (max-width: 768px) {
    &__toast {
      max-width: 350px; 
    }
  }

  @media (max-width: 467px) {
    &__toast {
      max-width: 90%; 
     margin-left: auto;
    }
  }
}

