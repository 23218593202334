@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", Sans-serif;
    scroll-behavior: smooth;
}


.container{
    max-width: 1240px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;

    
}


li{
    list-style: none;
}

a{
    text-decoration: none;
}

// @media(max-width:467px){
//     .container{
//         overflow: hidden;
//     }
// }

// @media(max-width:768px){
//     .container{
//         overflow: hidden;
//     }
// }