.news-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 55px;
  flex-wrap: wrap;
}

.news-card {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 350px;
  flex: 1 1 calc(33.333% - 20px); 
  transition: transform 0.3s ease;
}

.news-card:hover {
  transform: translateY(-5px);
}

.news-image {
  width: 100%;
  height: auto;
  border-radius: 15px 15px 0 0;
  object-fit: cover;
}

.news-content {
  padding: 20px;
}

.date {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

.news-title {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
}

.news-text {
  font-size: 16px;
  color: #555;
}

.news-header {
  text-align: center;
  margin: 50px 50px;
  h4{
    margin-top: 10px;
  }
}


@media (max-width: 768px) {
  .news-container {
    flex-direction: column;
    align-items: center;
  }

  .news-card {
    flex: 1 1 100%; 
    max-width: 500px;
    margin-bottom: 20px;
  }

  .news-title {
    font-size: 18px;
  }

  .news-text {
    font-size: 14px;
  }
}

@media (max-width: 467px) {
  .news-container {
    gap: 15px;
  }

  .news-card {
    max-width: 100%; 
    margin-bottom: 15px;
  }

  .news-title {
    font-size: 16px;
  }

  .news-text {
    font-size: 13px;
  }
}
