.quality {
  height: 600px;
  background-color: rgba(0, 0, 0, 0.733);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.quality-img {
  width: 700px;
  margin: 15px 0 0 30px;
}

.quality-img img {
  width: 100%;
  padding: 15px;
  border-radius: 10px; 
}

.quality-text {
  width: auto;
  margin-top: 135px;
  padding: 0 50px;
  color: white;
  text-align: right;
}

.quality-text h3 {
  font-size: 3rem;
  font-weight: 400;
}

.quality-text h2 {
  font-size: 4rem;
  font-weight: bold;
}

.quality-text h1 {
  font-size: 6rem;
  font-weight: bold;
  line-height: 1;
}

.main-icons {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.cards {
  align-items: center;
  max-width: 285px;
  width: 100%;
  display: flex;
  gap: 20px;
  h4{
    font-size: 16px;
  }
}

.cards p {
  margin-top: 4px;
}

@media (max-width: 768px) {

  .quality {
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .quality-img {
    width: 100%;
    max-width: 500px;
    margin: 0;
  }

  .quality-text {
    margin-top: 20px;
    padding: 0 20px;
    text-align: center;
  }

  .quality-text h1 {
    font-size: 4rem;
  }

  .quality-text h2 {
    font-size: 3rem;
  }

  .quality-text h3 {
    font-size: 2rem;
  }

  .main-icons{
    width: 100%;
    padding: 0 50px;
    font-size: 18px;
    display: grid;
    grid-template-columns: auto auto;
    .cards{
     text-align: center;
      display: block;
    }
  }
}

@media (max-width: 467px) {
  
  .quality {
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 10px;
  }

  .quality-img {
    width: 100%;
    max-width: 300px;
    margin: 0;
  }

  .quality-text {
    margin-top: 10px;
    padding: 0 15px;
    text-align: center;
  }

  .quality-text h1 {
    font-size: 3rem;
  }

  .quality-text h2 {
    font-size: 2.5rem;
  }

  .quality-text h3 {
    font-size: 1.8rem;
  }
  h4{
    font-size: 14px;
  }

  .main-icons {
    
    margin-top: 30px;
    padding: 0 20px;
    font-size: 12px;
    gap: 10px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    svg {
      width: 38px;
      height: 28px;
    }
    h4{
      font-size: 14px;
    }
  }
  .cards:nth-child(3),
  .cards:nth-child(4) {
    margin-top: 10px;
  }
  

  .cards p {
    margin-top: 3px;
  }
}
