.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 20px;
  color: #333;

  h1 {
    font-size: 96px;
    color: #ff6b6b;
    margin: 0;
  }

  h2 {
    font-size: 32px;
    margin: 10px 0;
  }

  p {
    font-size: 18px;
    margin: 20px 0;
    max-width: 600px;
  }

  .home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #ff6b6b;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff4c4c;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .notfound-container h1 {
    font-size: 72px;
  }

  .notfound-container h2 {
    font-size: 28px;
  }

  .notfound-container p {
    font-size: 16px;
  }
}

@media (max-width: 467px) {
  .notfound-container h1 {
    font-size: 56px;
  }

  .notfound-container h2 {
    font-size: 24px;
  }

  .notfound-container p {
    font-size: 14px;
  }
}
